<template>
  <div class="bg-whiteBase py-20 shadow-inner">
    <!-- <div class="absolute align-middle bottom-0 right-0">
      <a href="https://wa.me/6281299556161" target="_blank">
        <img
          src="../assets/wa.svg"
          alt=""
          class="w-2/12 h-aut0 fixed z-40 right-0 object-right p-3 -mt-32 -mr-1 md:p-8 md:-mr-4 md:-mt-48 lg:p-14 lg:-mr-9 lg:-mt-52 xl:p-iconWa xl:-mt-56 xl:-mr-16 2xl:-mt-72 2xl:p-32 2xl:-mr-28"
      /></a>
      <svg
        v-show="visible"
        id="backToTopID"
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        fill="currentColor"
        class="bi bi-arrow-up-circle-fill text-blackBlue fixed z-50 right-0 object-right -mt-16 mr-1 px-2 md:px-0 md:mr-6 md:-mt-20 xl:-mt-16 xl:p-1 2xl:-mt-24 2xl:mr-4 cursor-pointer"
        viewBox="0 0 16 16"
        @click="scrollToTop"
      >
        <path
          d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"
        />
      </svg>
    </div> -->
    <div class="xl:hidden">
      <img
        src="../assets/background/bg-top.webp"
        alt=""
        class="hidden md:block img1 w-10/12 ml-bgMobile pl-20 backdrop-opacity-80 md:w-11/12 md:ml-bgTab md:pl-40 md:-mt-8 lg:ml-bgLg xl:hidden"
      />
      <img
        src="../assets/logo/logo-top.webp"
        alt=""
        class="w-9/12 mx-auto relative md:-mt-96 md:w-5/12 lg:w-4/12 lg:mt-mlButtonBeranda"
      />
      <img
        src="../assets/background/bg-bottom.webp"
        alt=""
        class="md:hidden -mt-36 pt-1 md:-mt-80 lg:-mt-96 xl:mt-mlButtonBeranda 2xl:mt-mtBerandaLayananBottomDekstop"
      />
      <div class="text-center mt-10 mx-6 2xl:mx-24 2xl:mt-16 xl:mt-20">
        <h2
          class="text-2xl mx-12 tracking-wider md:pt-20 md:text-4xl md:leading-snug 2xl:mr-mrBerandaLayananTitle2xl 2xl:pt-24 lg:x-auto lg:pr-0 lg:text-5xl lg:mx-6 2xl:leading-snug xl:text-5xl xl:mr-80 xl:ml-16 xl:leading-tight"
        >
          TENTANG KAMI
        </h2>
        <hr
          class="tentang1 border w-2/5 mx-auto mt-3 mb-6 md:w-1/6 2xl:border-8 relative 2xl:w-1/12 xl:mx-16"
        />
        <img src="../assets/kutip.svg" alt="" class="-ml-0 md:mx-6 lg:mx-48" />
        <p
          class="text-sm -mt-20 pt-1 mx-8 mb-8 md:text-xl md:mx-20 2xl:mr-mrBerandaLayananTitle2xl 2xl:pr-32 lg:mx-52 lg:pr-0 2xl:text-lg xl:ml-16 xl:text-lg xl:mr-mtBerandaKarirButtonDekstop xl:pr-36"
        >
          PT. Troya Indo Perkasa adalah perusahaan Konsultasi Asuransi yang
          berdiri sejak 28 Juli 2020. Perusahaan ini bertujuan memberikan solusi
          untuk konsultasi Asuransi Umum.
        </p>
      </div>
    </div>
    <div class="hidden xl:grid xl:grid-cols-2 2xl:mb-10">
      <div class="text-center mt-10 mx-6 2xl:mx-24 2xl:mt-16 xl:mt-20">
        <h2
          class="text-2xl mx-12 tracking-wider md:pt-20 md:text-4xl md:leading-snug lg:x-auto lg:pr-0 lg:text-5xl lg:mx-6 2xl:leading-snug xl:text-5xl xl:mr-0 xl:text-left xl:-mt-20 xl:ml-16 xl:leading-tight 2xl:ml-5"
        >
          TENTANG KAMI
        </h2>
        <hr
          class="tentang2 w-2/5 mx-auto mt-3 mb-6 md:w-1/6 2xl:border-8 relative xl:mx-16 2xl:ml-5"
        />
        <img
          src="../assets/kutip.svg"
          alt=""
          class="-ml-4 md:mx-6 lg:mx-48 xl:mx-14 2xl:mx-6"
        />
        <p
          class="text-sm -mt-20 pt-1 mx-4 mb-8 md:text-xl md:mx-20 lg:mx-52 lg:pr-0 xl:ml-20 xl:text-lg xl:mr-36 2xl:ml-14 2xl:text-xl 2xl:mr-60"
        >
          PT. Troya Indo Perkasa adalah perusahaan Konsultasi Asuransi yang
          berdiri sejak 28 Juli 2020. Perusahaan ini bertujuan memberikan solusi
          untuk konsultasi Asuransi Umum.
        </p>
      </div>
      <div class="-ml-40 2xl:mt-6">
        <img
          src="../assets/logo/logo-top.webp"
          alt=""
          class="w-9/12 mx-auto relative md:-mt-96 md:w-5/12 lg:w-4/12 lg:mt-mlButtonBeranda xl:mt-16 xl:w-7/12 xl:ml-32 xl:mb-10 2xl:w-6/12"
        />
        <img
          src="../assets/background/bg-tentang.webp"
          alt=""
          class="md:hidden -mt-36 pt-1 md:-mt-80 lg:-mt-96 xl:block xl:-mt-96 2xl:mt-mlButtonBeranda 2xl:pt-8"
        />
      </div>
    </div>
    <div class="xl:grid xl:grid-cols-2">
      <div
        class="text-center mt-16 mx-6 md:text-left md:mt-10 2xl:mx-24 2xl:mt-0 xl:mt-20"
      >
        <h2
          class="2xl:ml-7 2xl:text-3xl text-2xl mx-12 tracking-widest md:pt-20 md:text-3xl md:ml-4 md:mr-80 md:leading-snug 2xl:mr-0 2xl:pt-24 lg:mr-96 lg:pr-36 lg:mx-6 2xl:leading-snug xl:text-3xl xl:mr-0 xl:ml-16 xl:leading-tight xl:pt-0"
        >
          VISI KAMI
        </h2>
        <hr
          class="2xl:ml-7 2xl:w-1/6 tentang border w-2/6 mx-auto mb-8 md:mr-auto md:ml-4 md:w-1/5 lg:w-visiLine 2xl:border-8 lg:mx-6 xl:mx-16"
        />
        <p
          class="2xl:ml-7 text-base text-left mx-6 mb-8 md:text-lg md:ml-4 md:-mr-1 md:mt-8 2xl:mr-0 2xl:pr-48 lg:mr-0 lg:pr-0 lg:mx-6 2xl:text-lg xl:ml-16 xl:text-lg xl:mr-10 xl:pr-0"
        >
          Visi perusahaan kami adalah menjadikan perusahaan Jasa Konsultasi
          Asuransi terpercaya.
        </p>
      </div>
      <div
        class="text-center mt-14 mx-10 md:mt-0 md:text-left 2xl:mx-24 2xl:mt-16 xl:mt-20"
      >
        <h2
          class="2xl:text-3xl 2xl:ml-12 text-2xl mx-12 tracking-widest md:pt-10 md:text-3xl md:ml-0 md:mr-80 md:leading-snug 2xl:mr-0 2xl:pt-6 lg:mr-96 lg:pr-36 lg:ml-2 2xl:leading-snug xl:text-3xl xl:mr-0 xl:ml-16 xl:leading-tight xl:pt-0"
        >
          MISI KAMI
        </h2>
        <hr
          class="2xl:ml-12 tentang border w-2/6 mx-auto mb-8 md:mr-auto md:ml-0 md:w-1/5 2xl:border-8 lg:w-misiLine lg:mx-2 2xl:w-3/12 xl:mx-16"
        />
        <ul
          class="list-outside list-none text-left md:mt-12 md:-ml-2 lg:ml-0 relative xl:mr-16 2xl:mr-52"
        >
          <li class="flex mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              class="bi bi-check-circle-fill text-brownC -mt-2 md:p-2"
              viewBox="0 0 16 16"
            >
              <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
              />
            </svg>
            <p class="ml-3 text-base md:text-lg">
              Berkomitmen Tinggi dalam memberikan pelayanan dalam bidang
              asuransi.
            </p>
          </li>
          <li class="flex mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              class="bi bi-check-circle-fill text-brownC -mt-2 md:p-2"
              viewBox="0 0 16 16"
            >
              <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
              />
            </svg>
            <p class="ml-3 text-base md:text-lg">
              Mengembangkan SDM (Sumber Daya Manusia) yang berkompetensi tinggi.
            </p>
          </li>
          <li class="flex mb-4">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              class="bi bi-check-circle-fill text-brownC -mt-2 md:p-2 xl:p-iconMisi"
              viewBox="0 0 16 16"
            >
              <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
              />
            </svg>
            <p class="ml-3 text-base md:text-lg">
              Memperluas jaringan Layanan Asuransi yang berstandar tinggi.
            </p>
          </li>
        </ul>
      </div>
    </div>
    <img
      src="../assets/background/bg-bottom.webp"
      alt=""
      class="hidden md:block xl:hidden col-span-2 mt-20 -mx-10 2xl:mt-mtBerandaLayananBottomDekstop lg:mt-10 xl:mt-mtBerandaLayananLg"
    />
    <div
      class="mt-20 mx-6 md:-mt-96 md:grid md:grid-cols-2 md:ml-10 md:gap-6 md:mb-14 lg:mb-60 lg:mx-14 lg:mt-mtBerandaButtonBottomDekstop xl:grid-cols-4 xl:mx-24 xl:mt-32 2xl:mx-32 2xl:mt-20 2xl:gap-2"
    >
      <div
        class="relative bg-transparent pt-8 xl:pt-0 mb-6 text-center md:text-left md:-mt-0 md:mx-0 md:mb-0 2xl:py-20"
      >
        <img
          src="../assets/tentang/fasilitas.png"
          alt=""
          class="mx-auto w-2/12 md:ml-0"
        />
        <h4
          class="text-2xl mt-4 font-semibold md:text-2xl lg:text-3xl xl:text-2xl 2xl:mt-8"
        >
          Fasilitas Asuransi
        </h4>
        <p
          class="text-base mt-4 px-8 pb-8 md:pl-0 md:mr-10 md:text-base lg:text-lg lg:mr-20 xl:text-base xl:mr-5 2xl:pl-0 2xl:mr-32 2xl:pr-7"
        >
          PT. Troya Indo Perkasa memiliki fasilitas asuransi yang mampu membantu
          memenuhi kebutuhan anda seperti, asuransi kendaraan, asuransi
          kesehatan dll.
        </p>
      </div>
      <div
        class="bg-transparent mb-6 text-center md:text-left md:pt-8 md:mx-0 md:mb-0 xl:pt-0 2xl:py-20"
      >
        <img
          src="../assets/tentang/klaim.png"
          alt=""
          class="mx-auto w-2/12 md:ml-0"
        />
        <h4
          class="text-2xl mt-4 font-semibold md:text-2xl lg:text-3xl xl:text-2xl 2xl:mt-8"
        >
          Klaim Asuransi Mudah
        </h4>
        <p
          class="text-base mt-4 px-10 pb-8 md:pl-0 md:mr-10 md:text-base lg:text-lg lg:mr-28 xl:text-base xl:mr-14 2xl:pl-0 2xl:mr-32 2xl:pr-7"
        >
          PT. Troya Indo Perkasa membantu anda untuk mengklaim layanan asuransi
          secara mudah dan cepat.
        </p>
      </div>
      <div
        class="bg-transparent mb-6 pb-6 text-center md:text-left md:mx-0 md:mb-0 2xl:py-20"
      >
        <img
          src="../assets/tentang/ojk.png"
          alt=""
          class="mx-auto w-2/12 md:ml-0 xl:-ml-12"
        />
        <h4
          class="xl:-ml-12 text-2xl mt-4 font-semibold md:text-2xl lg:text-3xl xl:text-2xl 2xl:mt-8"
        >
          Terdaftar Otoritas Jasa Keuangan
        </h4>
        <p
          class="xl:-ml-12 text-base mt-4 px-14 pb-2 md:px-0 md:mr-20 md:text-base lg:text-lg lg:mr-36 xl:text-base xl:mr-14 2xl:pl-0 2xl:mr-32 2xl:pr-14"
        >
          PT. Troya Indo Perkasa sudah terdaftar pada otoritas jasa keuangan
          pada 19 Agustus 2020.
        </p>
        <div class="md:flex md:-mt-2 xl:grid xl:-ml-12 xl:mr-6">
          <p
            class="text-base mt-4 px-6 pb-2 md:px-0 md:text-base 2xl:text-base 2xl:px-14 lg:text-lg xl:text-base 2xl:pl-0 2xl:mr-32 2xl:pr-7"
          >
            Nomor OJK:
          </p>
          <p
            class="xl:-ml-0 xl:tracking-widest xl:mr-20 xl:text-center text-base mx-14 border-dashed md:text-base md:mt-3 border lg:text-lg xl:text-base p-1 border-brownA md:mx-2 md:px-4 md:my-auto 2xl:pl-0 2xl:mr-48 2xl:pr-0"
          >
            S-1259/NB.122/2020
          </p>
        </div>
      </div>
      <div
        class="bg-transparent mb-6 text-center md:text-left md:mx-0 md:mb-0 2xl:py-20"
      >
        <img
          src="../assets/tentang/cs.png"
          alt=""
          class="mx-auto w-2/12 md:ml-0"
        />
        <h4
          class="text-2xl mt-4 font-semibold md:text-2xl lg:text-3xl xl:text-2xl 2xl:mt-8"
        >
          Layanan Situs Help Center
        </h4>
        <p
          class="text-base mt-4 px-6 pb-8 mx-4 md:pl-0 md:mr-10 md:text-base md:ml-0 xl:text-base xl:mr-6 lg:text-lg lg:mr-28 2xl:pl-0 2xl:mr-32 2xl:pr-12"
        >
          PT. Troya Indo Perkasa memiliki situs yang dapat membantu anda
          menghubungi customer service kami untuk setiap pertanyaan anda.
        </p>
      </div>
    </div>
    <div class="-mt-16 2xl:mb-16">
      <Apply />
    </div>
  </div>
</template>

<script>
import Apply from "@/components/Apply.vue";

export default {
  name: "Tentang",
  components: {
    Apply,
  },
  data() {
    return {
      visible: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.getIcon);
  },
  destroyed() {
    window.removeEventListener("scroll", this.getIcon);
  },
  methods: {
    getIcon(event) {
      if (event.path[1].pageYOffset < 100) {
        this.visible = false;
      } else {
        this.visible = true;
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style>
hr.tentang1 {
  border: 1.5px solid #c3986b;
  background-color: #c3986b;
}
hr.tentang2 {
  border: 3px solid #c3986b;
  background-color: #c3986b;
}
hr.tentang {
  border: 1px solid #c3986b;
  background-color: #c3986b;
}
</style>